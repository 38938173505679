import taxIdParents from '../../data/taxIdParents'

const getFromTax = function(tax, subTax,op) {


  if(op === 'id'){
  var _taxId = taxIdParents[tax][subTax].id;
    return _taxId;
    }
    if(op === 'name'){
    var _taxId = taxIdParents[tax][subTax].name;
      return _taxId;
      }
      return false;
}
export default getFromTax;
