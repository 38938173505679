import React,{ useState, useEffect, memo } from 'react'
import { NavLink, Link } from 'react-router-dom'
import taxIdParents from '../data/taxIdParents'
import getNavFromTax from './functions/getNavFromTax'

const NavbarSub = memo( (props) => {

  const [liElem,setLiElem] = useState();
  //getNavFromTax(props.slug);

  /*useEffect(() => {
    //console.log(getNavFromTax(props.slug));
    setLiElem( getNavFromTax(props.slug, props.pathnameCheck) );
 }, [props.slug]);
*/
 useEffect(() => {
   // declare the data fetching function
   const getSubNav = async () => {
     const data = await getNavFromTax(props.slug, props.pathnameCheck) ;
     setLiElem(data);
   }
   // call the function
   getSubNav()
     // make sure to catch any error
     .catch(console.error);
 }, [props.slug]);

  return (
    <div style={{zIndex:9}} className="bg-white-transparent fixed border-b border-b-grey-500 mt-[50px] w-full inline-flex flex-nowrap overflow-scroll pt-1 pb-1">
      {liElem ?
        <ul className="fadeIn pt-2 pb-1 flex items-center justify-center md:justify-start [&_li]:max-w-none [&_p]:max-w-none" dangerouslySetInnerHTML={{__html: liElem }}/>
        :
        <ul className="fadeIn pt-1 pb-0 flex items-center justify-center md:justify-start [&_li]:max-w-none [&_p]:max-w-none">

           <li>
             <NavLink className="p-1 pt-2" to="/news">
               <span className=" inline p-2 pl-2 ml-1 ">
                 News
               </span>
             </NavLink>
           </li>

           <li>
             <NavLink className="p-1 pt-2" to="/scores">
               <svg className="w-5 inline ml-1" version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 60 90" style={{enableBackground:'new 0 0 60 90'}}>
             <g>
               <path fill="#fff" className="st0" d="M21.8,42.7c2,0,3.4,0.6,4.7,2.1c1.5,1.8,4.5,2,6,0.3c1.6-1.9,3.5-2.4,5.9-2.4c2.5,0,4.3-2.4,4-4.9c-0.2-1.7,0.4-3.1,1.6-4.2c2.6-2.3,2.5-4.7,0-7.3c-1-1-1.4-2.5-2.1-3.8c0.6-3.3-1-5.3-4.1-5.2c-2,0.1-3.6-0.6-4.9-2.1c-1.7-2-4.5-2.1-6.1-0.2c-1.5,1.8-3.3,2.4-5.5,2.4c-2.6-0.1-4.6,2.1-4.3,4.7c0.2,1.9-0.4,3.3-1.8,4.4c-2.3,1.9-2.2,4.9,0.1,6.9c1.2,1,2,2.3,1.8,3.9C16.7,40.7,18.5,42.7,21.8,42.7z M27.4,27.3l2.1-6.6l2.1,6.6h6.9L33,31.4l2.1,6.6l-5.6-4.1l-5.6,4.1l2.1-6.6l-5.6-4.1H27.4z"/>
               <path fill="#bc0c0c" d="M9.5,80.7c1.4-3.5,9.1-22.8,10.5-26.4c0.6-1.5,1.1-3.1,1.8-4.5c0.2-0.5,1-1.1,1.3-1c1.6,0.4,2.7,2.8,2.1,4.4c-1.6,4-7.3,18.2-10.4,26.2l5.4-1.7c3.4-8.6,7.7-19.3,9.2-23.2c0.2,0,0.3,0,0.5,0c1.5,3.8,6.2,15,9.9,24l5.6,1.8c-3.2-7.9-9.5-23.3-11-26.8c-0.7-1.7-0.5-2.8,0.8-4c1.4-1.4,2-1.3,2.7,0.5c2,4.9,10.2,24.8,13.2,32.2l5.5,1.8c-0.1-0.5-0.4-1-0.6-1.5C53.9,77.6,44.5,55,42.4,50c-0.5-1.2-0.6-2,1-2.5c3-1,4.4-3.5,4.1-6.6c-0.2-2.3,0.5-4.2,2.3-5.8c3.6-3.2,3.6-7-0.1-10.2c-1.5-1.3-2.4-3-2.2-5.1c0.4-5.1-1.9-7.6-7-7.5c-2.5,0.1-4.4-0.8-6-2.7c-2.4-3-6.8-3.2-9.2-0.3c-1.9,2.3-4.1,3.1-6.9,3c-4.3-0.2-6.8,2.8-6.4,7c0.2,2.1-0.4,4-2,5.4C6,28,5.9,31.6,9.7,35.1c1.8,1.6,2.5,3.6,2.2,6c-0.1,0.9,0,1.8,0.3,2.6c0.8,2.5,2.8,3.6,5.4,4.4C15.1,54.5,6.3,76.5,3.8,82.7c0,0,0,0,0.1,0l5.4-1.6C9.3,81,9.4,80.8,9.5,80.7zM17,37.4c0.2-1.6-0.6-2.9-1.8-3.9c-2.3-2-2.3-5-0.1-6.9c1.4-1.2,2-2.6,1.8-4.4c-0.3-2.6,1.6-4.8,4.3-4.7c2.3,0,4-0.6,5.5-2.4c1.6-1.9,4.4-1.7,6.1,0.2c1.3,1.6,2.9,2.2,4.9,2.1c3.1-0.1,4.7,1.8,4.1,5.2c0.7,1.3,1.1,2.8,2.1,3.8c2.5,2.6,2.6,5,0,7.3c-1.2,1.1-1.8,2.4-1.6,4.2c0.3,2.5-1.5,4.9-4,4.9c-2.4,0-4.3,0.5-5.9,2.4c-1.5,1.7-4.6,1.5-6-0.3c-1.3-1.5-2.8-2.2-4.7-2.1C18.5,42.7,16.7,40.7,17,37.4z"/>
               <polygon fill="#bc0c0c" points="22.8,39.7 29.5,34.8 36.2,39.7 33.6,31.8 40.4,26.9 32.1,26.9 29.5,19 26.9,26.9 18.7,26.9 25.4,31.8 	"/>
             </g>
             </svg>
             </NavLink>
           </li>
           <li>

             <NavLink className="p-1 pt-1" to="/scores">
                <span className=" inline p-1 ml-0">Scores</span>
             </NavLink>
           </li>

           <li>
             <NavLink className="p-1 pt-1 " to="/reports">
               <span className=" inline p-2 pl-1 ml-1 ">Reports</span>
               </NavLink>
           </li>
           <li>
             <NavLink className="p-1 pt-1" to="/market">
               <span className=" inline p-2 pl-1 ">Market</span>
               </NavLink>
           </li>

           <li>
             <NavLink className="p-0 mt-1" to="/search">
               <svg className="inline pl-0" xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill="#000" d="M14.648 12.788l-4.23-4.228c.525-.855.834-1.858.834-2.938 0-3.105-2.52-5.624-5.627-5.624-3.106.002-5.625 2.521-5.625 5.627 0 3.105 2.519 5.625 5.625 5.625 1.076 0 2.08-.309 2.936-.832l4.229 4.229c.194.195.515.195.707 0l1.151-1.146c.194-.2.194-.519 0-.713zm-13.35-7.163c0-2.39 1.938-4.327 4.327-4.327 2.391 0 4.328 1.937 4.328 4.327 0 2.391-1.936 4.327-4.328 4.327-2.39 0-4.327-1.936-4.327-4.327z"/>
               </svg>

            </NavLink>
           </li>

       </ul>
      }
    </div>
  )
});

export default NavbarSub
