import React,{useState, useRef, useEffect, useContext, memo} from 'react';
import { Outlet, NavLink, Link, useLoaderData } from "react-router-dom";
import ProgressiveImage from './ProgressiveImage/FirstSlider';
import BackgroundImage from './ProgressiveImage/BackgroundImage';
import EdBlock from './EdBlock';
import EdBlockQl from './EdBlockQl';
import EdAdDeluxe from './EdAdDeluxe';
import EdAdElite from './EdAdElite';
import EdAdGroup from './EdAdGroup';
import EdBlockTax from './EdBlockTax';
import { ThemeContext } from '../context/ThemeContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Virtual, Pagination } from 'swiper/modules';
import he from 'he';
import {gsap} from "gsap";

import equalHeights from './functions/equalHeights';
import fetchStoryWithRetry from './functions/fetchStoryWithRetry';
import getKeyFromTax from './functions/getKeyFromTax';

import "animate.css/animate.min.css";

import 'swiper/css';
import 'swiper/css/virtual';
import 'swiper/css/pagination';



const Home = memo( (props) => {

  const edHost = process.env.REACT_APP_HOST_URL;
  var storageDate = new Date().getTime();
  var cacheExpire = 15; //expiry of cache in minutes
  //console.log(props);
  let itemsData = useLoaderData();
  itemsData.ed.storageDate = storageDate;
  //  window.localStorage.setItem(props.slug,JSON.stringify(itemsData.ed));
  //  window.localStorage.setItem('d_' + props.slug,storageDate);

  let itemsHome_1 = itemsData.ed.slice(0,5);
  let itemsHome_2 = itemsData.ed.slice(5,itemsData.ed.length);

  const titlesRef = useRef();
  const [dataHomeTwo, setDataHomeTwo] = useState();
  const [dateOffset, setDateOffset] = useState();

  const theme = useContext(ThemeContext);

  /*const slideCallback = () => {
    if(titlesRef.current){
    titlesRef.current.classList.add('show');
    }else{
      //console.log('failed')
    }
  }*/

  const loadDataHomeTwo = async () => {

    //As we always have page on on the page swipers, we begin at #2

    const _url = `${edHost}/api/ed/home/two`;
    const _local_data = JSON.parse(localStorage.getItem('home-two'));
    const _local_storageDate = JSON.parse(localStorage.getItem('d_home-two'));
    var _now = new Date().getTime();
    var diffInMin = (_now - _local_storageDate)/1000/60;
    //console.log(diffInMin);

      if(_local_data && diffInMin < cacheExpire){ //10 minutes cache
        setDataHomeTwo(_local_data);
        setDateOffset(_local_data[0].date);

        console.log('Cache for home-two ' + diffInMin +' min');
        return;
      }else{
        //fetch
    try {
      const data = await fetchStoryWithRetry(_url,'home-two');
        setDataHomeTwo(null);
        setDateOffset(null);
        setDataHomeTwo(data.ed);
        var _dateOffset = data.ed[0].date;
        setDateOffset(_dateOffset);

        console.log('Setting cache for ' + 'home-two' );

        window.localStorage.setItem('home-two',JSON.stringify(data.ed));
        window.localStorage.setItem('d_home-two',storageDate);


    } catch (error) {
      console.error('Failed to fetch the story after retries:', error);
      return null; // Handle the error appropriately
    }
      }

  }
  useEffect(()=>{
    if(props.submenu !== theme.state.submenu){
    theme.dispatch({submenu: props.submenu, pushOut:false, width:theme.state.width, height:theme.state.height, vertical:theme.state.vertical,type:'viewport' });
    console.log('submenu');
      }
      },[props.submenu]);

/*useEffect((props) => {
    var t = setTimeout(function(){
  slideCallback();
  },2000);
},[]);*/

useEffect((props) => {
  var t = setTimeout(function(){
    equalHeights('equalHeight');
      equalHeights('equalHeight_1');
    clearTimeout(t);
  },800);

  loadDataHomeTwo();

},[itemsData.ed]);

  return (
    <div id="home" className={props.transition} style={{paddingTop:'80px'}}>
      <section className={"w-100 pt-[0px] " + props.transition}>
        <Swiper
              className="swiperFull"
              modules={[Virtual, Pagination]}
              spaceBetween={10}
              //slidesPerView={theme.state.width < 700 ? 1 : 3 }
              slidesPerView={1}
              virtual={true}
              //cache={true}
              resistanceRatio={0.3}
              speed={800}
              //addSlidesAfter={4}
              //addSlidesBefore={4}
              loop={false}
              pagination={true}
              onInit={(swiper) => {
                //slideCallback();
              }}
            /*  onSlideChange={(swiper) =>  {
                var slds = swiper.slides;
                console.log(slds);

                if(swiper.activeIndex > swiper.previousIndex){ // to the right
                //  activeTitle.classList.remove('animate__animated', 'animate__fadeIn', 'animate__delay-1s');
                //  nextTitle.classList.add('animate__animated', 'animate__fadeIn', 'animate__delay-1s');

                }else{
                //  activeTitle.classList.remove('animate__animated', 'animate__fadeIn', 'animate__delay-1s');
                //  prevTitle.classList.add('animate__animated', 'animate__fadeIn', 'animate__delay-1s');
                }

              }}*/
              onSlideChange={(swiper) => {
                equalHeights('equalHeight_1');
                //slideCallback();
              }}

            >
            {itemsHome_1.length > 0 ? itemsHome_1.map((item,index) => (




              <SwiperSlide key={'tax_1-'+ index} virtualIndex={index}>
              <div key={'home_1-'+ index}  style={{}} className="flex flex-col items-center p-0 space-y-3 text-center">
              <NavLink onClick={(e)=> e.currentTarget.classList.add('selected')} className="edStoryMain pb-2 rounded-b-xl overflow-hidden w-[100vw]" to={item.url} state={{ from: 'home' }}>

              <picture style={{position:'relative !important', overflow:'hidden', minHeight: 'calc(98vw * (538 / 680))'}} id={'btn-' + index} className="relative">
              <BackgroundImage
              src={edHost + item.image_thumb}
              placeholder={'/static/trans.gif'}
              modal={theme.modal}
              />
                <div className="_overlay"></div>
              <div ref={titlesRef} className={'slideOverlayTitle bg-featured-dark p-2 text-center center rounded-md' + (index === 0 ? ' show' : '') }>
              <h3  className={'leading-0 text-2md rounded-md block'}>{he.decode(item.title)}</h3>
            </div>

            </picture>

              <div className="equalHeight rounded-b-xl flex-col items-center p-4 space-y-1 text-center bg-magenta-featured  dark:bg-magenta-featured">

                  <p className=" text-sm leading-0 pb-0">{item.date}</p>
                    <p className="headlineGrid text-md edTextGradient leading-0 pb-3">{item.headline}</p>

                {/* <p className="mt-2 button bg-white rounded-xl text-sm p-2">Read the full story</p> */}

              </div>
            </NavLink>
              </div>
            </SwiperSlide>

                    ))  : ''}
             </Swiper>

      </section>

      <section>
        <div className="text-center pt-1 pb-2">
        <EdAdDeluxe url={`${edHost}/rest/ed/ad/deluxe`} name=""/>
      </div>
      </section>


<section style={{overflow:'hidden'}} className={"w-100 pt-6 p-2 " + props.transition}>
  <div className="innerSection">
    <p className="text-base text-medium tracking-wide pl-2 pb-2">
      <a href="/news/dressage"><strong>Eurodressage</strong>
        <svg className="w-4 h-4 ml-1 inline edTextGradient" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill="#ee2525" fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        <span className="text-sm">{dateOffset ? ' prior to '+ dateOffset : ''}</span>
    </a>
    </p>
<Swiper
      className=" rounded-xl flex swiperHalf"
      modules={[Virtual, Pagination]}
      spaceBetween={4}
      //slidesPerView={theme.state.width < 700 ? 1 : 3 }
      slidesPerView={1}
      virtual={true}
      //cache={true}
      resistanceRatio={0.3}
      speed={800}
      //addSlidesAfter={4}
      //addSlidesBefore={4}
      loop={false}
      pagination={true}
      onSlideChange={(swiper) => {
        equalHeights('equalHeight_1');
      }}
      //onSwiper={(swiper) => equalHeights('equalHeight') }
    >
      {dataHomeTwo ? dataHomeTwo.map((item,index) => (


      <SwiperSlide key={'home_2-'+ index} virtualIndex={index}>
        <div style={{}} className="items-center text-center">

        <NavLink className="glow rounded-xl overflow-hidden" to={item.url} state={{ from: 'home' }}>

          <picture style={{maxHeight: '300px',overflow:'hidden', minHeight: 'calc(98vw * (538 / 680))'}} id={'btn-' + index} className=".link">
        <ProgressiveImage
        src={edHost + item.image_thumb}
        placeholder={'/static/trans.gif'}
        modal={theme.modal}
        bg={'#333'}
        imgMargin={props.imgMargin}
        imgWidth={100}
        />
        <div className="_overlay"></div>
        </picture>

        <div className="equalHeight_1 rounded-b-xl flex flex-col items-center p-2 space-y-1 text-center bg-magenta  dark:bg-magenta">

            <h1 className="storyTitle text-2xl pt-3 leading-7 tracking-wide font-medium pb-6">{he.decode(item.title)}</h1>
            <p className=" text-sm leading-0 pb-0">{item.date}</p>
              <p className="headlineGrid text-md edTextGradient leading-0 pb-3">{item.headline}</p>

        </div>

      </NavLink>
        </div>
        <p>&nbsp;</p>
    </SwiperSlide>

            ))  : ''}
     </Swiper>
   </div>
</section>

<section>
  <div className="text-center pt-1 pb-2">
  <EdAdElite url={`${edHost}/rest/ed/ad/elite`} slug="elite" name=""/>
</div>
</section>

{/*
<section>
  <div className="text-center pt-3 pb-8">
  <svg className="w-12 inline ml-1" version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
   viewBox="0 0 60 90" style={{enableBackground:'new 0 0 60 90'}}>
  <g>
  <path fill="#fff" className="st0" d="M21.8,42.7c2,0,3.4,0.6,4.7,2.1c1.5,1.8,4.5,2,6,0.3c1.6-1.9,3.5-2.4,5.9-2.4c2.5,0,4.3-2.4,4-4.9c-0.2-1.7,0.4-3.1,1.6-4.2c2.6-2.3,2.5-4.7,0-7.3c-1-1-1.4-2.5-2.1-3.8c0.6-3.3-1-5.3-4.1-5.2c-2,0.1-3.6-0.6-4.9-2.1c-1.7-2-4.5-2.1-6.1-0.2c-1.5,1.8-3.3,2.4-5.5,2.4c-2.6-0.1-4.6,2.1-4.3,4.7c0.2,1.9-0.4,3.3-1.8,4.4c-2.3,1.9-2.2,4.9,0.1,6.9c1.2,1,2,2.3,1.8,3.9C16.7,40.7,18.5,42.7,21.8,42.7z M27.4,27.3l2.1-6.6l2.1,6.6h6.9L33,31.4l2.1,6.6l-5.6-4.1l-5.6,4.1l2.1-6.6l-5.6-4.1H27.4z"/>
  <path fill="#bc0c0c" d="M9.5,80.7c1.4-3.5,9.1-22.8,10.5-26.4c0.6-1.5,1.1-3.1,1.8-4.5c0.2-0.5,1-1.1,1.3-1c1.6,0.4,2.7,2.8,2.1,4.4c-1.6,4-7.3,18.2-10.4,26.2l5.4-1.7c3.4-8.6,7.7-19.3,9.2-23.2c0.2,0,0.3,0,0.5,0c1.5,3.8,6.2,15,9.9,24l5.6,1.8c-3.2-7.9-9.5-23.3-11-26.8c-0.7-1.7-0.5-2.8,0.8-4c1.4-1.4,2-1.3,2.7,0.5c2,4.9,10.2,24.8,13.2,32.2l5.5,1.8c-0.1-0.5-0.4-1-0.6-1.5C53.9,77.6,44.5,55,42.4,50c-0.5-1.2-0.6-2,1-2.5c3-1,4.4-3.5,4.1-6.6c-0.2-2.3,0.5-4.2,2.3-5.8c3.6-3.2,3.6-7-0.1-10.2c-1.5-1.3-2.4-3-2.2-5.1c0.4-5.1-1.9-7.6-7-7.5c-2.5,0.1-4.4-0.8-6-2.7c-2.4-3-6.8-3.2-9.2-0.3c-1.9,2.3-4.1,3.1-6.9,3c-4.3-0.2-6.8,2.8-6.4,7c0.2,2.1-0.4,4-2,5.4C6,28,5.9,31.6,9.7,35.1c1.8,1.6,2.5,3.6,2.2,6c-0.1,0.9,0,1.8,0.3,2.6c0.8,2.5,2.8,3.6,5.4,4.4C15.1,54.5,6.3,76.5,3.8,82.7c0,0,0,0,0.1,0l5.4-1.6C9.3,81,9.4,80.8,9.5,80.7zM17,37.4c0.2-1.6-0.6-2.9-1.8-3.9c-2.3-2-2.3-5-0.1-6.9c1.4-1.2,2-2.6,1.8-4.4c-0.3-2.6,1.6-4.8,4.3-4.7c2.3,0,4-0.6,5.5-2.4c1.6-1.9,4.4-1.7,6.1,0.2c1.3,1.6,2.9,2.2,4.9,2.1c3.1-0.1,4.7,1.8,4.1,5.2c0.7,1.3,1.1,2.8,2.1,3.8c2.5,2.6,2.6,5,0,7.3c-1.2,1.1-1.8,2.4-1.6,4.2c0.3,2.5-1.5,4.9-4,4.9c-2.4,0-4.3,0.5-5.9,2.4c-1.5,1.7-4.6,1.5-6-0.3c-1.3-1.5-2.8-2.2-4.7-2.1C18.5,42.7,16.7,40.7,17,37.4z"/>
  <polygon fill="#bc0c0c" points="22.8,39.7 29.5,34.8 36.2,39.7 33.6,31.8 40.4,26.9 32.1,26.9 29.5,19 26.9,26.9 18.7,26.9 25.4,31.8 	"/>
  </g>
  </svg>
  <h3> LATEST SCORES</h3>
  <ul>
    <li>xxxxx</li>
    <li>xxxxx</li>
    <li>xxxxx</li>
    <li>xxxxx</li>
    <li>xxxxx</li>
</ul>
</div>
</section>
*/}
<section>
  <div className="text-center pt-1 pb-2">
  <EdBlock url={`${edHost}/api/ed/block/mrotw`} slug="mrotw" name="Most read this week"/>
</div>
</section>

<section>
  <div className="text-center pt-1 pb-2">
  <EdAdGroup url={`${edHost}/rest/ed/ad/frontpage`} slug="frontpage" name=""/>
</div>
</section>

<section>
  <div className="text-center pt-1 pb-2">
 <EdBlockTax url={`${edHost}/api/ed/story/cat/27/0/0`} slug="market" tax={27} name="Market"/>
</div>
</section>

<section>
  <div className="text-center pt-1 pb-2">
  <EdBlockQl url={`${edHost}/rest/ed/block/ql`} name="Championships Quick Links"/>
</div>
</section>

<section>
  <div className="text-center pt-1 pb-2">
 <EdBlockTax url={`${edHost}/api/ed/story/cat/41/0/0`} slug="auctions" tax={41} name="Auctions"/>
</div>
</section>





</div>
  )
});

export default Home
