import React from 'react'

function Footer(props) {
  return (
    <div className={"p-6 text-center text-sm " + props.transition}>
  &copy; <span className="tracking-wider text-medium edTextGradient">Eurodressage</span> 2024 &bull; <a className="link" href="/legal">Legal</a> <br/> <a style={{color:'#555'}} className="text-sm tracking-wide pb-2" href="https://remiblot.com">Crafted by Rémi Blot</a>
</div>
  )
}

export default Footer
