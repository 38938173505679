import { useState } from "react"
import { NavLink } from "react-router-dom";
import ProgressiveImage from './ProgressiveImage/FirstSlider';
import he from 'he';
import fetchStoryWithRetry from './functions/fetchStoryWithRetry';


const edHost = process.env.REACT_APP_HOST_URL;

export default function LoadMore() {



  const loadData = async (url, tax, page, offset) => {

    const _url = `${url}${tax}/${page}/${offset}`;
    try {
      const data = await fetchStoryWithRetry(_url);
      setDataMore(data.ed.rows);

    } catch (error) {
      console.error('Failed to fetch the story after retries:', error);
      return null; // Handle the error appropriately
    }
  }

  const [numChildren, setNumChildren] = useState(0);
  const [dataMore, setDataMore] = useState(null);
  const children = [];
    const dataArray = [];



  for (let i = 0; i < numChildren; i++) {
    children.push(<ChildComponent key={i} number={i} data={dataMore}/>);
  }

  const addComponent = () => {
    setNumChildren((count) => count + 1);
  var data = loadData(`${edHost}/api/ed/story/cat/`, 12, 1, 0) ;
setDataMore(data);
dataArray.push(data);

  }

  return (
    <div className="App">
      <ParentComponent addComponent={addComponent}>{children}</ParentComponent>
    </div>
  )
}

const ParentComponent = ({ children, addComponent }) => {
  return (
    <div className="pt-24 text-center">
      <div>{children}</div>
      <button className="text-center" onClick={addComponent}>Load More</button>

    </div>
  )
}

const ChildComponent =  (props) => {

  return (

    <div className="columns-2 gap-1">
    {/*  <h4 className="glow">This is a child component {dataArray[props.number]}</h4> */}
         {props.data.length > 0 ? props.data.map((item,index) => (



            <div key={'tax_2-'+ index} style={{}} className="rounded-xl pt-1 overflow-hidden text-center">

            <NavLink className="glow overflow-hidden" to={item.url} state={{ from: 'home' }}>

            <picture style={{display:'block',overflow:'hidden'}} id={'btn-' + index} className="">
            <ProgressiveImage
            src={edHost + item.image_thumb}
            placeholder={'/static/trans.gif'}
            modal={null}
            bg={'#333'}
            imgMargin={props.imgMargin}
            imgWidth={50}
            />
            </picture>

            <div className="equalHeight_1 text-left bg-magenta dark:bg-magenta">

                <p className="p-2 text-left storyTitle-sm ls-small clampStoryLinesmall">{he.decode(item.title)}</p>
                <p className="pl-2 headline-sm pb-0 ls-small">{item.date}</p>
                <p className="pl-2 edTextGradient headline-sm leading-1 ls-small pb-3">{he.decode(item.headline)}</p>


            </div>

          </NavLink>
            </div>


                ))  : ''}

            </div>

)
};
