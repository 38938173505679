const taxIdParents = {
  'scores': {
          'scores':{'name' : 'Scores', 'id': 22},
          '2024': {'name' : '2024', 'id': 141},
          '2023': {'name': '2023', 'id': 140},
        },
  'news': {
          'news': {'name' : 'News', 'id': 11},
          'dressage': {'name' : 'Dressage', 'id': 12},
          'breeding': {'name': 'Breeding', 'id': 13},
          'what-is-happening': {'name' : 'What is happening', 'id' : 14},
          'veterinary': {'name': 'Veterinary', 'id': 15},
          'focus': {'name': 'Focus','id': 23},
        },
  'reports': {
          'reports': {'name' : 'Reports', 'id': 16},
          'shows': {'name' : 'Shows', 'id': 17},
          'interviews': {'name': 'Interviews', 'id': 21},
          'history': {'name' : 'History', 'id' : 40},
          'training': {'name': 'Training', 'id': 18},
          'walks': {'name': 'Walks','id': 19},
        },
  'market': {
          'market':{'name' : 'Market', 'id': 27},
          'equimarket': {'name' : 'Equimarket', 'id': 32},
          'young-stock': {'name': 'Young Stock', 'id': 33},
          'stallion-market': {'name': 'Stallion Market', 'id': 34},
          'grooms-corner': {'name' : 'Job Offers', 'id' : 35},
          'real-estate': {'name': 'Real Estate', 'id': 70},
          'photography': {'name': 'Photography','id': 36},
          'events': {'name': 'Events','id': 123},
          'books-dvd': {'name': 'Books-DVD','id': 37},
          'products': {'name': 'Products','id': 64},
        },
  'para': {
          'para': {'name' : 'Paralympics', 'id': 130},
          'news': {'name' : 'News', 'id': 131},
          'show-reports': {'name': 'Show Reports', 'id': 132},
          'blogs': {'name' : 'Blogs', 'id' : 133},
        },
  'auctions': {
          'auctions': {'name' : 'Auctions', 'id': 41},
          'psi': {'name' : 'PSI', 'id': 42},
          'vechta': {'name': 'Oldenburg', 'id': 45},
          'verden': {'name' : 'Verden', 'id' : 46},
          'westfalian': {'name' : 'Westfalian', 'id' : 44},
          'van-olst-sales': {'name' : 'Van Olst Sales', 'id' : 128},
          'excellent-dressage-sales':{'name' : 'Excellent Dressage Sales', 'id' : 127},
          'netherlands': {'name' : 'Brightwells', 'id' : 108},
          'germany': {'name' : 'Germany', 'id' : 138},
          'scandinavia': {'name' : 'Scandinavia', 'id' : 139},
          'classic-sales-warendorf': {'name' : 'Classic Sales Warendorf', 'id' : 47},
          'misc': {'name' : ' Miscellaneous', 'id' : 50},
          'brightwells': {'name' : 'Germany', 'id' : 138},
          'klosterhof-medingen': {'name' : 'Klosterhof Medingen', 'id' : 49},
          'equine-elite': {'name' : 'Equine Elite', 'id' : 43},
        },
  'editorials': {
          'editorials':{'name' : 'Editorials', 'id': 38},
          'eurodressage-editorials': {'name' : 'Editorials', 'id': 32},
          'guest-columnists': {'name': 'Guest Columnists', 'id': 68},
          'david-stickland': {'name': 'David Stickland', 'id': 124},
          'wayne-s-world': {'name' : "Wayne's World", 'id' : 67},
          'real-estate': {'name': 'Real Estate', 'id': 70},
          'photography': {'name': 'Photography','id': 36},
          'events': {'name': 'Events','id': 123},
          'books-dvd': {'name': 'Books-DVD','id': 37},
          'blogs': {'name': 'Blogs','id': 134},
        },

};
export default taxIdParents;
