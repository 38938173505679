import React,{useState, useEffect, useContext, memo} from 'react';
import { NavLink, Link, useLocation} from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import he from 'he';
import fetchStoryWithRetry from './functions/fetchStoryWithRetry';
import _, { isEqual } from 'underscore';

import 'swiper/css';
import 'swiper/css/virtual';
import 'swiper/css/pagination';



const EdAdGroup = memo( (props) => {

  const url = props.url;
  //console.log('_tax_',tax);
  const location = useLocation();
  const [dataGroup,setDataGroup] = useState();



  const loadDataToBlock = async () => {

    const _url = url;


    try {
      const data = await fetchStoryWithRetry(_url,props.slug);
      setDataGroup(null);

      setDataGroup(prev => {
    if (!_.isEqual(prev, data)) {
      return data;
      console.log('data');
    } else {
      return prev;
      console.log('data');
    }
  });
        //setDataGroup(null);
        //setDataGroup(data);


    } catch (error) {
      console.error('Failed to fetch the story after retries:', error);
      return null; // Handle the error appropriately
    }

  }

  useEffect(() => {
  loadDataToBlock();
    },[location]);


  return (
    <div className={props.transition}>

<section style={{overflow:'hidden'}} className={"border-b-2 border-gray-200 fadeIn w-100 pt-0 p-0 " + props.transition}>
  <p className="text-sm text-center tracking-wide pl-2 edLinkIcon p-2 pb-0">
    <strong className="linkFile text-gray-400">
    {props.name}
  </strong>
 </p>
  <div className="innerSection relative">
            <Swiper
              className="p-0 m-0"
              slidesPerView={1}
              spaceBetween={0}
              preventClicks={false}
              loop={true}
              autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
            >
          { dataGroup ? dataGroup.map((item,index) => (

          <SwiperSlide key={index} className="relative block border-t-2 center text-center border-gray-200 p-0">
          <a style={{zIndex:5, width:'100vw',height:'100%',display:'block'}} href={item.url} target="_self" className="block p-0">
            <img style={{zIndex:2, margin: '0 auto',width:'auto'}} id={'group-' + item.id} className="bannerGroup p-0 text-center" src={item.image.replace('/site','https://eurodressage.com/site')} alt=""/>
            </a>
        </SwiperSlide>
          ))  : ''}
      </Swiper>
</div>
</section>
</div>
)
});

export default EdAdGroup;
