import React,{useState, useRef, useEffect, useContext,memo} from 'react';
import { NavLink, Link, useLoaderData, useParams, useLocation} from "react-router-dom";
import ProgressiveImage from './ProgressiveImage/FirstSlider';
import { ThemeContext } from '../context/ThemeContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Virtual, Pagination} from 'swiper/modules';
import he from 'he';
import { nanoid } from "nanoid";
import SearchScores from './SearchScores'
import equalHeights from './functions/equalHeights';
import fetchStoryWithRetry from './functions/fetchStoryWithRetry';
import getFromTax from './functions/getFromTax'
import getKeyFromTax from './functions/getKeyFromTax';

import 'swiper/css';
import 'swiper/css/virtual';
import 'swiper/css/pagination';





const TaxonomyMainScores = memo((props) => {


  const edHost = process.env.REACT_APP_HOST_URL;
  var linkRef = useRef();

  const location = useLocation();
  var itemsData = useLoaderData();

  var storageDate = new Date().getTime();
  var cacheExpire = 10; //expiry of cache in minutes
  //console.log(itemsData);
  window.localStorage.setItem(location.pathname,JSON.stringify(itemsData.ed))
  window.localStorage.setItem('d_' + location.pathname,storageDate);
  const tax = props.slug;

  const theme = useContext(ThemeContext);


  const [taxId, setTaxId] = useState();
  const [nanoId, setNanoId] = useState();
  const [taxName, setTaxName] = useState();
  const [dataLoad,setDataLoad] = useState();
  const [dataScore_1,setDataScore_1] = useState();
  const [dataScore_2,setDataScore_2] = useState();
  const [monthArray,setMonthArray] = useState();
  const [dataMore, setDataMore] = useState();
  const [page, setPage] = useState(0);
  const [pagerInfo, setPagerInfo] = useState('Loading ...');
  const [dataPager, setDataPager] = useState();
  const [dir, setDir] = useState(1);
  const [offsetItems, setOffsetItems] = useState(0);
  const [dateOffset, setDateOffset] = useState();

  const taxLink = (parentTax, tax, name) => {
    linkRef.current.innerHTML = '<a href="/'+ parentTax+'/' + tax +'">'+name+'</a>';
  }

  const loadData = async (url, page, offset, dir,context) => {
    if(context === 'dataMore'){
      setDataMore(null);
      setPage(null);
      setPagerInfo('Loading...');

    //As we always have page on on the page swipers, we begin at #2
    var pager = (page + dir) < 2 ? 1 : page + dir;
    setPage(pager);
    const _url = `${url}/${pager}/${offset}`;



    try {
      const data = await fetchStoryWithRetry(_url,`${tax}-${pager}-${offset}`);
        setDataPager(data.ed.pager);
        setDataMore(data.ed.rows);

    } catch (error) {
      console.error('Failed to fetch the story after retries:', error);
      return null; // Handle the error appropriately
    }
    }
    if(context === 'dataLoad'){
      setDataScore_1(null);
      setDataScore_2(null);
      setDataMore(null);
      setPage(null);
      setDataPager(null);
      setDateOffset();
      const _url = `${url}/0/0`;

      const _local_data = JSON.parse(localStorage.getItem(location.pathname));
      const _local_storageDate = JSON.parse(localStorage.getItem('d_' + location.pathname ));
      var _now = new Date().getTime();
      var diffInMin = (_now - _local_storageDate)/1000/60;

      if(_local_data && diffInMin < cacheExpire){
        setDataLoad(_local_data.rows);
        var itemsTaxonomy_1 = _local_data.rows.slice(0,5);
        var itemsTaxonomy_2 = _local_data.rows.slice(5,_local_data.pager.items_per_page);
        var _dateOffset = _local_data.rows[4].date;
        setDataPager(_local_data.pager);
        setDateOffset(_dateOffset);
        setDataScore_1(itemsTaxonomy_1);
        setDataScore_2(itemsTaxonomy_2);

        console.log('Reading from cache for ' + location.pathname + ' ' + diffInMin +' min');

        return;
      }else{
        //fetch

      try {
        const data = await fetchStoryWithRetry(_url,props.slug);
          setDataLoad(data.ed.rows);
          var itemsTaxonomy_1 = data.ed.rows.slice(0,5);
          var itemsTaxonomy_2 = data.ed.rows.slice(5,data.ed.pager.items_per_page);
          var _dateOffset = data.ed.rows[4].date;
          setDataPager(data.ed.pager);
          setDateOffset(_dateOffset);
          setDataScore_1(itemsTaxonomy_1);
          setDataScore_2(itemsTaxonomy_2);

          console.log('Setting cache for ' + location.pathname );

      } catch (error) {
        console.error('Failed to fetch the story after retries:', error);
        return null; // Handle the error appropriately
      }
      }
    }
  }

  //console.log(props);

  /*var itemsTaxonomy_1 = itemsData.ed.rows.slice(0,5);
  var itemsTaxonomy_2 = itemsData.ed.rows.slice(5,itemsData.ed.pager.items_per_page);
  setDataScore_1(itemsTaxonomy_1);
  setDataScore_2(itemsTaxonomy_2);*/

  /*let itemsTv = itemsData.items['tv'];
  let itemsDoc = itemsData.items['doc'];*/
  //console.log(itemsData.items);


useEffect(()=>{
  if(props.submenu !== theme.state.submenu){
  theme.dispatch({submenu: props.submenu, pushOut:false, width:theme.state.width, height:theme.state.height, vertical:theme.state.vertical,type:'viewport' });
  //console.log('submenu');
    }
    },[props.submenu]);

  useEffect(() => {
  //console.log(location);

  setNanoId(nanoid());
  setTaxId(getFromTax(props.slug,tax,'id'));
  setTaxName(getFromTax(props.slug,tax,'name'));
  loadData(`${edHost}/api/ed/tax/scores/`, 0, 0, -1, 'dataLoad');
    },[location]);

  useEffect(() => {
    equalHeights('equalHeight_2');
  },[dataMore]);

useEffect((props) => {
  var t = setTimeout(function(){
    equalHeights('equalHeight');
      equalHeights('equalHeight_1');
    clearTimeout(t);

    //var items = loadData(11,2,0,`${edHost}/api/ed/tax/scores/`);
    //console.log(items);
    //console.log('equalHeight');
  },800);


},[dataScore_1]);

  return (
    <div id="taxonomyMoreScore" className={ props.transition} style={{paddingTop:'80px'}}>

      <section className={"w-100 pt-12 p-2 " + props.transition}>
        <div className="text-center pt-0 pb-0">
        <svg className="w-12 inline ml-0" version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         viewBox="0 0 60 90" style={{enableBackground:'new 0 0 60 90'}}>
        <g>
        <path fill="#fff" className="st0" d="M21.8,42.7c2,0,3.4,0.6,4.7,2.1c1.5,1.8,4.5,2,6,0.3c1.6-1.9,3.5-2.4,5.9-2.4c2.5,0,4.3-2.4,4-4.9c-0.2-1.7,0.4-3.1,1.6-4.2c2.6-2.3,2.5-4.7,0-7.3c-1-1-1.4-2.5-2.1-3.8c0.6-3.3-1-5.3-4.1-5.2c-2,0.1-3.6-0.6-4.9-2.1c-1.7-2-4.5-2.1-6.1-0.2c-1.5,1.8-3.3,2.4-5.5,2.4c-2.6-0.1-4.6,2.1-4.3,4.7c0.2,1.9-0.4,3.3-1.8,4.4c-2.3,1.9-2.2,4.9,0.1,6.9c1.2,1,2,2.3,1.8,3.9C16.7,40.7,18.5,42.7,21.8,42.7z M27.4,27.3l2.1-6.6l2.1,6.6h6.9L33,31.4l2.1,6.6l-5.6-4.1l-5.6,4.1l2.1-6.6l-5.6-4.1H27.4z"/>
        <path fill="#bc0c0c" d="M9.5,80.7c1.4-3.5,9.1-22.8,10.5-26.4c0.6-1.5,1.1-3.1,1.8-4.5c0.2-0.5,1-1.1,1.3-1c1.6,0.4,2.7,2.8,2.1,4.4c-1.6,4-7.3,18.2-10.4,26.2l5.4-1.7c3.4-8.6,7.7-19.3,9.2-23.2c0.2,0,0.3,0,0.5,0c1.5,3.8,6.2,15,9.9,24l5.6,1.8c-3.2-7.9-9.5-23.3-11-26.8c-0.7-1.7-0.5-2.8,0.8-4c1.4-1.4,2-1.3,2.7,0.5c2,4.9,10.2,24.8,13.2,32.2l5.5,1.8c-0.1-0.5-0.4-1-0.6-1.5C53.9,77.6,44.5,55,42.4,50c-0.5-1.2-0.6-2,1-2.5c3-1,4.4-3.5,4.1-6.6c-0.2-2.3,0.5-4.2,2.3-5.8c3.6-3.2,3.6-7-0.1-10.2c-1.5-1.3-2.4-3-2.2-5.1c0.4-5.1-1.9-7.6-7-7.5c-2.5,0.1-4.4-0.8-6-2.7c-2.4-3-6.8-3.2-9.2-0.3c-1.9,2.3-4.1,3.1-6.9,3c-4.3-0.2-6.8,2.8-6.4,7c0.2,2.1-0.4,4-2,5.4C6,28,5.9,31.6,9.7,35.1c1.8,1.6,2.5,3.6,2.2,6c-0.1,0.9,0,1.8,0.3,2.6c0.8,2.5,2.8,3.6,5.4,4.4C15.1,54.5,6.3,76.5,3.8,82.7c0,0,0,0,0.1,0l5.4-1.6C9.3,81,9.4,80.8,9.5,80.7zM17,37.4c0.2-1.6-0.6-2.9-1.8-3.9c-2.3-2-2.3-5-0.1-6.9c1.4-1.2,2-2.6,1.8-4.4c-0.3-2.6,1.6-4.8,4.3-4.7c2.3,0,4-0.6,5.5-2.4c1.6-1.9,4.4-1.7,6.1,0.2c1.3,1.6,2.9,2.2,4.9,2.1c3.1-0.1,4.7,1.8,4.1,5.2c0.7,1.3,1.1,2.8,2.1,3.8c2.5,2.6,2.6,5,0,7.3c-1.2,1.1-1.8,2.4-1.6,4.2c0.3,2.5-1.5,4.9-4,4.9c-2.4,0-4.3,0.5-5.9,2.4c-1.5,1.7-4.6,1.5-6-0.3c-1.3-1.5-2.8-2.2-4.7-2.1C18.5,42.7,16.7,40.7,17,37.4z"/>
        <polygon fill="#bc0c0c" points="22.8,39.7 29.5,34.8 36.2,39.7 33.6,31.8 40.4,26.9 32.1,26.9 29.5,19 26.9,26.9 18.7,26.9 25.4,31.8 	"/>
        </g>
        </svg>
      </div>
        <h1 className="text-2xl text-center pb-4 fadeIn"><span className="edTextGradient">{taxName}</span> </h1>






          <SearchScores/>
            <div className="p-2 mt-6 w-full block rounded-2xl bg-featured-quickLinks">
              <ul className="w-80 ml-4">
            {dataScore_1 ? dataScore_1.map((item,index) => (
              <React.Fragment key={nanoid()}>
                            {index === 0 ? <li key="m1" className="text-sm text-white pl-11 pt-4"><h3>{item.month} {item.year}</h3></li> : ''}
                            {index > 0 && item.month !== dataScore_1[index-1].month ? <li key={'m1-'+index} className="text-sm text-white pl-11 pt-4"><h3>{item.month} {item.year}</h3> </li>: ''}

              <li  className="list" key={'tax_1-'+ index}>

                  <span className="nbr text-sm mr-2">{index+1}</span>
                  <NavLink onClick={(e)=> e.currentTarget.classList.add('selected')} className="text-white ml-4 flex" to={item.url} state={{ from: tax }}>
                                        {he.decode(item.title).replace('Scores:','')}<br/><small>{item.date}</small>
                  </NavLink>

              </li>

                </React.Fragment>  ))  : ''}
              </ul>
              </div>
  </section>



<section style={{overflow:'hidden'}} className={" fadeIn w-100 pt-12 p-2 " + props.transition}>
  <div className="innerSection">
    <p className="text-medium text-base tracking-wide pl-2 pb-2"><strong>{taxName}</strong> <svg className="w-4 h-4 ml-1 inline edTextGradient" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill="#ee2525" fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
      <span className="text-sm">{dateOffset ? ' prior to '+ dateOffset : ''}</span>
      <span style={{fontWeight:600}} ref={linkRef} className="text-bold pl-1 edTextGradient"></span>
  </p>


  <div className="p-2 w-full block rounded-2xl bg-magenta">
    <ul className="w-80 ml-4">
  {dataScore_2 ? dataScore_2.map((item,index) => (
    <React.Fragment key={nanoid()}>
      {index === 0 ? <li key="m-2" className="text-sm edTextGradient pl-11 pt-4"><h3>{item.month} {item.year}</h3></li> : ''}
      {index > 0 && item.month !== dataScore_2[index-1].month ? <li key={'m2-'+index} className="text-sm edTextGradient pl-11 pt-4"><h3>{item.month} {item.year}</h3> </li>: ''}

    <li  className="list light" key={'tax_2-'+ index}>

        <span className="nbr mr-2 text-sm bg-gray-200">{index+6}</span>
        <NavLink onClick={(e)=> e.currentTarget.classList.add('selected')} className="ml-4 flex" to={item.url} state={{ from: tax }}>
                              {he.decode(item.title).replace('Scores:','')}<br/><small>{item.date}</small>
        </NavLink>

    </li>

      </React.Fragment>  ))  : ''}
    </ul>
    </div>

        {page > 0 ?
          <div className="pager mt-8 mb-4 pb-0 center columns-3 gap-1 text-center rounded-xl">
            <div className="text-center ml-14">
              <button aria-label="pagination" onClick={()=>{ loadData(`${edHost}/api/ed/tax/scores/`, page, offsetItems, -1, 'dataMore');} }>
            {page > 1 ?
              <svg className="loaderMore mt-1 w-6 h-7 ml-1 inline edTextGradient rotate-180" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill="#ee2525" fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
              : ''}
              </button>
          </div>
            <div className="p-1 bg-magenta">
              <button aria-label="pagination" onClick={()=>{ loadData(`${edHost}/api/ed/tax/scores/`, page, offsetItems, 1, 'dataMore');} }>
          <p className="leading-0  text-sm text-center tracking-wide font-medium pb-0"> {dataPager ? (dataPager.current_page +1)+' / '+ dataPager.total_pages : 'Load More' } </p>
          </button>
        </div>
            <div className=" text-center  mr-14 ">
              <button aria-label="pagination" onClick={()=>{ loadData(`${edHost}/api/ed/tax/scores/`, page, offsetItems, 1, 'dataMore');} }>
              <svg className="mt-1 w-6 h-7 ml-1 inline edTextGradient" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill="#ee2525" fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
              </button>
            </div>
        </div>
        :''
      }
    <div className="bg-magenta rounded-2xl">
      <ul className="w-80 ml-4">
      {dataMore ? dataMore.map((item,index) => (

        <React.Fragment key={nanoid()}>
          {index === 0 ? <li key="m-3" className="text-sm edTextGradient pl-11 pt-4"><h3>{item.month} {item.year}</h3></li> : ''}
          {index > 0 && item.month !== dataMore[index-1].month ? <li key={'m3-'+index} className="text-sm edTextGradient pl-11 pt-4"><h3>{item.month} {item.year}</h3> </li>: ''}

        <li  className="list light" key={'tax_1-'+ index}>

            <span className="nbr archive mr-2">&nbsp;</span>
            <NavLink onClick={(e)=> e.currentTarget.classList.add('selected')} className="ml-4 flex" to={item.url} state={{ from: tax }}>
                                  {he.decode(item.title).replace('Scores:','')}<br/><small>{item.date}</small>
            </NavLink>

        </li>




          </React.Fragment>  ))  :

           page > 0 ?  <div className="p-24 text-center center col-span-2">
              <svg style={{margin:'0 auto'}} version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
   width="40px" height="40px" viewBox="0 0 40 40" enableBackground="new 0 0 40 40">
  <path opacity="0.2" fill="#000000" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/>
  <path fill="#fff" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
    C22.32,8.481,24.301,9.057,26.013,10.047z">
    <animateTransform attributeType="xml"
      attributeName="transform"
      type="rotate"
      from="0 20 20"
      to="360 20 20"
      dur="0.5s"
      repeatCount="indefinite"/>
    </path>
  </svg>
            </div> : ''
          }
        </ul>
        </div>
        <div className="pager mt-8 pb-0 center columns-3 gap-1 text-center rounded-xl fadeIn delay">
          <div className="text-center ml-14">
            <button aria-label="pagination" onClick={()=>{ loadData(`${edHost}/api/ed/tax/scores/`, page, offsetItems, -1, 'dataMore');} }>
          {page > 1 ?
            <svg className="mt-1 w-6 h-7 ml-1 inline edTextGradient rotate-180" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill="#ee2525" fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            : ''}
            </button>
        </div>
          <div className="p-1 bg-magenta">
            <button aria-label="pagination" onClick={()=>{ loadData(`${edHost}/api/ed/tax/scores/`, page, offsetItems, 1, 'dataMore');} }>
        <p className="leading-0  text-sm text-center tracking-wide font-medium pb-0"> {dataPager ? (dataPager.current_page +1)+' / '+ dataPager.total_pages : 'Archive' } </p>
        </button>
      </div>
          <div className=" text-center  mr-14 ">
            <button aria-label="pagination" onClick={()=>{ loadData(`${edHost}/api/ed/tax/scores/`, page, offsetItems, 1, 'dataMore');} }>
            <svg className="mt-1 w-6 h-7 ml-1 inline edTextGradient" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill="#ee2525" fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </button>
          </div>
      </div>

   </div>
</section>

<section>

</section>




</div>
  )
});

export default TaxonomyMainScores
