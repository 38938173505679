import React,{useState, useRef,useEffect, memo} from 'react';
import { NavLink} from "react-router-dom";
import ProgressiveImage from './ProgressiveImage/FirstSlider';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Virtual, Pagination} from 'swiper/modules';
import he from 'he';
import { nanoid } from "nanoid";

import equalHeights from './functions/equalHeights';
import fetchStoryWithRetry from './functions/fetchStoryWithRetry';
import getKeyFromTax from './functions/getKeyFromTax';


import 'swiper/css';
import 'swiper/css/virtual';
import 'swiper/css/pagination';



const EdBlockTaxMore = memo( (props) => {

  const edHost = process.env.REACT_APP_HOST_URL;

  var nanoId = nanoid();
  var nanoId_1 = nanoid();

  var linkRef = useRef();
  //console.log('_tax_',tax);

  var storageDate = new Date().getTime();
  var cacheExpire = 15; //expiry of cache in minutes

  const [taxId, setTaxId] = useState();

  const [taxName, setTaxName] = useState();
  const [dataLoad,setDataLoad] = useState();
  const [dataSwiper_1,setDataSwiper_1] = useState();

  const [dataMore, setDataMore] = useState();
  const [page, setPage] = useState(0);
  const [pagerInfo, setPagerInfo] = useState('Loading ...');
  const [dataPager, setDataPager] = useState(0);

  const [offsetItems, setOffsetItems] = useState(0);


  const taxLink = (parentTax, tax, name) => {
    linkRef.current.innerHTML = '<a href="/'+ parentTax+'/' + tax +'">'+name+'</a>';
  }

  const loadData = async (url, tax, page, offset, dir,context) => {
    if(context === 'dataMore'){
      setDataMore(null);
      setPage(null);
      setPagerInfo('Loading...');

    //As we always have page on on the page swipers, we begin at #2
    var pager = (page + dir) < 2 ? 2 : page + dir;
    setPage(pager);
    const _url = `${url}${tax}/${pager}/${offset}`;

    try {
      const data = await fetchStoryWithRetry(_url,`${tax}-${pager}-${offset}`);
        setDataPager(data.ed.pager);
        setDataMore(data.ed.rows);

    } catch (error) {
      console.error('Failed to fetch the story after retries:', error);
      return null; // Handle the error appropriately
    }
    }
    if(context === 'dataLoad'){
      setDataSwiper_1(null);

      setDataMore(null);
      setPage(null);
      setDataPager(null);

      const _url = `${url}${tax}/0/0`;

      const _local_data = JSON.parse(localStorage.getItem('block-' + tax));
      const _local_storageDate = JSON.parse(localStorage.getItem('d_block-' + tax ));
      var _now = new Date().getTime();
      var diffInMin = (_now - _local_storageDate)/1000/60;

      if(_local_data && diffInMin < cacheExpire ){
        setDataLoad(_local_data.rows);
        var itemsTaxonomy_1 = _local_data.rows;
        setDataSwiper_1(itemsTaxonomy_1);

        console.log('Reading from cache for ' + 'block-' + tax + ' ' + diffInMin +' min');
        return;
      }else{

      try {
        const data = await fetchStoryWithRetry(_url);
          setDataLoad(data.ed.rows);
          var itemsTaxonomy_1 = data.ed.rows;
          setDataSwiper_1(itemsTaxonomy_1);

          window.localStorage.setItem('block-' + tax,JSON.stringify(data.ed));
          window.localStorage.setItem('d_block-' + tax,storageDate);

          console.log('Setting cache for ' + 'block-' + tax );

      } catch (error) {
        console.error('Failed to fetch the story after retries:', error);
        return null; // Handle the error appropriately
      }
      }
    }
  }





  useEffect(() => {
  //console.log(location);

  setTaxId(props.tax);
  setTaxName(props.name);
  //setTaxId(getFromTax(props.slug,props.tax,'id'));
  //setTaxName(getFromTax(props.slug,props.tax,'name'));
  loadData(`${edHost}/api/ed/story/cat/`, taxId, 0, 0, -1, 'dataLoad');
    },[props,edHost,taxId]);

  useEffect(() => {
    equalHeights(nanoId_1);
  },[dataMore,nanoId_1]);

useEffect((props) => {
  var t = setTimeout(function(){
    equalHeights(nanoId);
    clearTimeout(t);

    //var items = loadData(11,2,0,`${edHost}/api/ed/story/cat/`);
    //console.log(items);
    //console.log('equalHeight');
  },800);


},[dataSwiper_1,nanoId]);

  return (
    <div className={props.transition}>



<section style={{overflow:'hidden'}} className={" fadeIn w-100 pt-0 p-2 " + props.transition}>
  <div className="innerSection">
    <p className="text-medium text-base tracking-wide text-left pl-2 pb-2"><strong>{taxName}</strong> <svg className="w-4 h-4 ml-1 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill="#ee2525" fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
      <span style={{fontWeight:600}} ref={linkRef} className="text-bold pl-1 edTextGradient"></span>
  </p>


    <Swiper
          className=" rounded-xl flex swiperHalf"
          modules={[Virtual, Pagination]}
          spaceBetween={4}
          slidesPerView={2}
          virtual={true}
          //cache={true}
          resistanceRatio={0.3}
          speed={800}
          //addSlidesAfter={4}
          //addSlidesBefore={4}
          loop={false}
          pagination={true}
          onSlideChange={(swiper) => {
            equalHeights(nanoId);

            if(dataSwiper_1 && swiper.activeIndex){
            const _name = dataSwiper_1[swiper.activeIndex].cat ;
            const _subTaxSlug = getKeyFromTax(props.slug, _name);
            taxLink(props.slug, _subTaxSlug, _name);
            }


          }
        }
          //onSwiper={(swiper) => equalHeights('equalHeight') }
        >
          {dataSwiper_1 ? dataSwiper_1.map((item,index) => (


          <SwiperSlide key={'tax_2-'+ index} virtualIndex={index}>
            <div style={{}} className="items-center text-center">

            <NavLink onClick={(e)=> {e.currentTarget.classList.add('selected');console.log(e)}} className="edStory glow rounded-xl overflow-hidden" to={item.url} state={{ from: taxId }}>

            <picture style={{background:'#ddd', display:'block',overflow:'hidden', minHeight: 'calc(48vw * (538 / 680))'}} id={'btn-' + index} className="">
            <ProgressiveImage
            src={edHost + item.image_thumb}
            placeholder={'/static/trans.gif'}
            modal={null}
            bg={'#333'}
            width={680}
            height={538}
            imgMargin={props.imgMargin}
            imgWidth={50}
            />
            <div className="_overlay"></div>
            </picture>

            <div className={nanoId + " rounded-b-xl text-left bg-magenta dark:bg-magenta pb-2"}>

                <p className="p-2 text-left storyTitle-sm ls-small clampStoryLinesmall">{he.decode(item.title)}</p>
    {/*            <p className="pl-2 headline-sm pb-0 ls-small">{item.date}</p>
                <p className="pl-2 edTextGradient headline-sm leading-1 ls-small pb-3">{he.decode(item.headline)}</p>
*/}
            </div>

          </NavLink>
            </div>
            <p>&nbsp;</p>
        </SwiperSlide>

                ))  : ''}
         </Swiper>



          <div className="pager mt-1 mb-4 pb-0 center columns-1 gap-1  pl-20 pr-20 text-center ">
            <div className="text-center bg-magenta rounded-xl">
              <button aria-label="pagination" onClick={()=>{ loadData(`${edHost}/api/ed/story/cat/`, taxId, page, offsetItems, -1, 'dataMore');} }>
              {}  <span className="text-sm"><a href={'/' + props.slug}>View more</a></span>
              </button>
          </div>

            </div>
          </div>


</section>






</div>
  )
});

export default EdBlockTaxMore
