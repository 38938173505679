import React, { Component } from "react";
import PropTypes from "prop-types";

import './style.css';

class ProgressiveImage extends Component {
  static defaultProps = {
    alt: ""
  };

  constructor(props) {
    super(props);
    // initially set loading to true and current src of image to placeholder image
    this.state = {
      loading: true,
      currentSrc: props.placeholder,
      status: 'Loading'
    };
  }

  componentDidMount() {
    const { src } = this.props;
    // start loading original image
    const imageToLoad = new Image();
    imageToLoad.src = src;
    imageToLoad.onload = () =>{
      // When image is loaded replace the image's src and set loading to false
      this.setState({ currentSrc: src, loading: false });
      var num = Math.random(1000) + 200;
      var t = setTimeout(function(){
          this.setState({status: 'Loaded'});
        clearTimeout(t);
      }.bind(this),num);
      }
  }

  render() {
    const { currentSrc } = this.state;

    const v = (Math.random()*(100)|0).toString(16);
    //const bg = ['#222', '#333', '#444'];
    return (

      <img style={{
        backgroundColor: '#'+ v + v + v,//bg[Math.floor(Math.random() * 3)],
        marginLeft: -this.props.imgMargin+ 'px',
        width: this.props.imgWidth +'vw'
      }}
      data-src={this.props.src}
        src={currentSrc}
        loading="eager"
        height="auto" width="100%" className={'heightTransition image is' + this.state.status  } alt=""
      />

    );
  }
}

ProgressiveImage.propTypes = {
  src: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,

/*  pos: PropTypes.string.isRequired,
  location: PropTypes.string,
  title: PropTypes.string*/
};

export default ProgressiveImage;
