import taxIdParents from '../../data/taxIdParents'

const getNavFromTax = function(tax,pathnameCheck) {

  if(!tax){
    return false;
  }

  var _key = '';
  var  i = 0;
  var _str = '';
  var _class = '';

  // we create an array, this will respect the object key sorting
  var key_sorted = Object.keys(taxIdParents[tax]);
  if(tax === 'scores'){
    return;
    // keys numerical will be wrong sorted, numbers first in the object
    //this ensure sorting for this case
    key_sorted.reverse()
  }

  key_sorted.forEach((value, i) => {

    if(i == 0){
    _str += '<li><a class="p-1 pt-2 text-medium edTextGradient" href="/' + tax + '"><span class="inline w-auto p-1 ml-0 whitespace-nowrap">' + taxIdParents[tax][value].name + ':</span></a></li>' +'\n';
    }
    if(i > 0){
      if(pathnameCheck === value){
        _class = 'text-red-600';
      }else{
        _class = '';
      }
    //console.log('<li><a href="/' + tax + '/' + key + '">' + taxIdParents[tax][key].name + '</a></li>');
    _str += '<li><a class="p-1 pt-2" href="/' + tax + '/' + value + '"><span class="inline w-auto p-1 ml-0 whitespace-nowrap ' +_class + '">' + taxIdParents[tax][value].name + '</span></a></li>' +'\n';
}
  });


      //console.log(_str);
      return _str;

}
export default getNavFromTax;
