import React,{useState, useEffect, useContext, memo} from 'react';
import { NavLink, Link, useLoaderData, useParams, useLocation} from "react-router-dom";
import ProgressiveImage from './ProgressiveImage/FirstSlider';
import { ThemeContext } from '../context/ThemeContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Virtual, Pagination} from 'swiper/modules';
import he from 'he';
import { nanoid } from "nanoid";
import Loader from "./Loader";
import equalHeights from './functions/equalHeights';
import fetchStoryWithRetry from './functions/fetchStoryWithRetry';
import getFromTax from './functions/getFromTax'

import 'swiper/css';
import 'swiper/css/virtual';
import 'swiper/css/pagination';


const TaxonomyMore = memo((props) => {

  const edHost = process.env.REACT_APP_HOST_URL;

  const {tax} = useParams();
  var itemsData = useLoaderData();
  const location = useLocation();
  var storageDate = new Date().getTime();
  var cacheExpire = 15; //expiry of cache in minutes

  const _local_storageDate = JSON.parse(localStorage.getItem('d_' + location.pathname ));
  //console.log(_local_storageDate);
  if(!_local_storageDate){
  window.localStorage.setItem(location.pathname,JSON.stringify(itemsData.ed));
  window.localStorage.setItem('d_' + location.pathname,storageDate);
}

  //console.log('_tax_',tax);
  const [taxId, setTaxId] = useState();
  const [nanoId, setNanoId] = useState();
  const [taxName, setTaxName] = useState();
  const [dataLoad,setDataLoad] = useState();
  const [dataSwiper_1,setDataSwiper_1] = useState();
  const [dataSwiper_2,setDataSwiper_2] = useState();
  const [dataMore, setDataMore] = useState();
  const [page, setPage] = useState(0);
  const [pagerInfo, setPagerInfo] = useState('Loading ...');
  const [dataPager, setDataPager] = useState();
  const [dir, setDir] = useState(1);
  const [offsetItems, setOffsetItems] = useState(0);
  const [dateOffset, setDateOffset] = useState();



  const loadData = async (url, tax, page, offset, dir,context) => {
    if(context === 'dataMore'){
      setDataMore(null);
      setPage(null);
      setPagerInfo('Loading...');

    //As we always have page on on the page swipers, we begin at #2
    var pager = (page + dir) < 1 ? 2 : page + dir;
    setPage(pager);
    const _url = `${url}${tax}/${pager}/${offset}`;


    try {
      const data = await fetchStoryWithRetry(_url,`${tax}-${pager}-${offset}`);
        setDataPager(data.ed.pager);
        setDataMore(data.ed.rows);

    } catch (error) {
      console.error('Failed to fetch the story after retries:', error);
      return null; // Handle the error appropriately
    }
    }
    if(context === 'dataLoad'){
      setDataSwiper_1(null);
      setDataSwiper_2(null);
      setDataMore(null);
      setPage(null);
      setDataPager();
      setDateOffset();

      const _local_data = JSON.parse(localStorage.getItem(location.pathname));
      const _local_storageDate = JSON.parse(localStorage.getItem('d_' + location.pathname ));
      var _now = new Date().getTime();
      var diffInMin = (_now - _local_storageDate)/1000/60;

      if(_local_data && diffInMin < cacheExpire ){
        var itemsTaxonomy_1 = _local_data.rows.slice(0,5);
        var itemsTaxonomy_2 = _local_data.rows.slice(5,_local_data.pager.items_per_page);
        var _dateOffset = _local_data.rows[itemsTaxonomy_1.length - 1].date;
        setDataPager(_local_data.pager);
        setDateOffset(_dateOffset);
        setDataSwiper_1(itemsTaxonomy_1);
        setDataSwiper_2(itemsTaxonomy_2);

        console.log('Reading from cache for ' + location.pathname + ' ' + diffInMin +' min');
        return;
      }else{
        //fetch
      const _url = `${url}${tax}/0/0`;
      try {
        const data = await fetchStoryWithRetry(_url,props.slug);
          setDataLoad(data.ed.rows);
          var itemsTaxonomy_1 = data.ed.rows.slice(0,5);
          var itemsTaxonomy_2 = data.ed.rows.slice(5,data.ed.pager.items_per_page);
          var _dateOffset = data.ed.rows[itemsTaxonomy_1.length - 1].date;
          setDataPager(data.ed.pager);
          setDateOffset(_dateOffset);
          setDataSwiper_1(itemsTaxonomy_1);
          setDataSwiper_2(itemsTaxonomy_2);

          console.log('Setting cache for ' + location.pathname );

      } catch (error) {
        console.error('Failed to fetch the story after retries:', error);
        return null; // Handle the error appropriately
      }
      }
    }
  }

  //console.log(props);

  /*var itemsTaxonomy_1 = itemsData.ed.rows.slice(0,5);
  var itemsTaxonomy_2 = itemsData.ed.rows.slice(5,itemsData.ed.pager.items_per_page);
  setDataSwiper_1(itemsTaxonomy_1);
  setDataSwiper_2(itemsTaxonomy_2);*/

  /*let itemsTv = itemsData.items['tv'];
  let itemsDoc = itemsData.items['doc'];*/
  //console.log(itemsData.items);
  const theme = useContext(ThemeContext);



  useEffect(() => {
    if(props.submenu !== theme.state.submenu){
    theme.dispatch({submenu: props.submenu, pushOut:false, width:theme.state.width, height:theme.state.height, vertical:theme.state.vertical,type:'viewport' });
    //console.log('submenu');
      }
  setNanoId(nanoid());
  setTaxId(getFromTax(props.slug,tax,'id'));
  setTaxName(getFromTax(props.slug,tax,'name'));
  loadData(`${edHost}/api/ed/story/cat/`, getFromTax(props.slug,tax,'id'), 0, 0, -1, 'dataLoad');
    },[location.pathname]);

  useEffect(() => {
    equalHeights('equalHeight_2');
  },[dataMore]);

useEffect((props) => {
  var t = setTimeout(function(){
    equalHeights('equalHeight');
      equalHeights('equalHeight_1');
    clearTimeout(t);

    //var items = loadData(11,2,0,`${edHost}/api/ed/story/cat/`);
    //console.log(items);
    //console.log('equalHeight');
  },800);


},[dataSwiper_1]);

  return (
    <div id="taxonomyMore" className={props.transition} style={{paddingTop:'100px'}}>
      <section className={"w-100 pt-12 " + props.transition}>
        <h1 className="text-2xl text-center pb-4 fadeIn">{props.name} / <span className="edTextGradient">{taxName}</span> </h1>
    {dataSwiper_1 ? <Swiper
            className="swiperFull"
            modules={[Virtual, Pagination]}
            spaceBetween={10}
            //slidesPerView={theme.state.width < 700 ? 1 : 3 }
            slidesPerView={theme.state.width < 700 ? 1 : 2 }
            virtual={true}
            //cache={true}
            resistanceRatio={0.3}
            speed={800}
            //addSlidesAfter={4}
            //addSlidesBefore={4}
            loop={false}
            pagination={true}
            onSlideChange={() => equalHeights('equalHeight')}
            //onSwiper={(swiper) => equalHeights('equalHeight') }
          >
            {dataSwiper_1 ? dataSwiper_1.map((item,index) => (

            <SwiperSlide key={'tax_1-'+ index} virtualIndex={index}>
              <div style={{}} className="flex flex-col items-center p-2 space-y-3 text-center">
              <NavLink onClick={(e)=> e.currentTarget.classList.add('selected')} className="edStoryMain glow rounded-xl overflow-hidden" to={item.url} state={{ from: tax }}>

              <picture style={{maxHeight: '300px',overflow:'hidden', minHeight: 'calc(98vw * (538 / 680))'}} id={'btn-' + index} className=".link">
              <ProgressiveImage
              src={edHost + item.image_thumb}
              placeholder={'/static/trans.gif'}
              modal={theme.modal}
              bg={'#333'}
              imgMargin={props.imgMargin}
              />
              <div className="_overlay"></div>
              </picture>

              <div className="equalHeight flex flex-col items-center p-2 space-y-1 text-center bg-magenta  dark:bg-magenta">

                  <h1 className="storyTitle text-2xl leading-7 tracking-wide font-medium pb-6">{he.decode(item.title)}</h1>
                  <p className=" text-sm leading-0 pb-0">{item.date}</p>
                    <p className="headlineGrid text-md edTextGradient leading-0 pb-3">{he.decode(item.headline)}</p>

              </div>

            </NavLink>
              </div>
          </SwiperSlide>

                  ))  : ''}
           </Swiper> : <Loader/> }





  </section>


<section style={{overflow:'hidden'}} className={" fadeIn w-100 pt-0 p-2 " + props.transition}>
  <div className="innerSection">
    <p className="text-medium text-base tracking-wide pl-2 pb-2"><strong>{taxName}</strong> <svg className="w-4 h-4 ml-1 inline edTextGradient" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill="#ee2525" fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
      <span className="text-sm">{dateOffset ? ' prior to '+ dateOffset : ''}</span>
  </p>


    <Swiper
          className=" rounded-xl flex swiperHalf"
          modules={[Virtual, Pagination]}
          spaceBetween={4}
          //slidesPerView={theme.state.width < 700 ? 1 : 3 }
          slidesPerView={2}
          virtual={true}
          //cache={true}
          resistanceRatio={0.3}
          speed={800}
          //addSlidesAfter={4}
          //addSlidesBefore={4}
          loop={false}
          pagination={true}
          onSlideChange={() => equalHeights('equalHeight_1')}
          //onSwiper={(swiper) => equalHeights('equalHeight') }
        >
          {dataSwiper_2 ? dataSwiper_2.map((item,index) => (


          <SwiperSlide key={'tax_2-'+ index} virtualIndex={index}>
            <div style={{}} className="items-center text-center">

            <NavLink onClick={(e)=> {e.currentTarget.classList.add('selected');console.log(e)}} className="edStory glow rounded-xl overflow-hidden" to={item.url} state={{ from: tax }}>

            <picture style={{background:'#ddd', display:'block',overflow:'hidden', minHeight: 'calc(48vw * (538 / 680))'}} id={'btn-' + index} className="">
            <ProgressiveImage
            src={edHost + item.image_thumb}
            placeholder={'/static/trans.gif'}
            modal={theme.modal}
            bg={'#333'}
            width={680}
            height={538}
            imgMargin={props.imgMargin}
            imgWidth={50}
            />
            <div className="_overlay"></div>
            </picture>

            <div className="equalHeight_1 rounded-b-xl text-left bg-magenta dark:bg-magenta pb-2">

                <p className="p-2 text-left storyTitle-sm ls-small clampStoryLinesmall">{he.decode(item.title)}</p>
        {/*       <p className="pl-2 headline-sm pb-0 ls-small">{item.date}</p>
                <p className="pl-2 edTextGradient headline-sm leading-1 ls-small pb-3">{he.decode(item.headline)}</p>
          */}
          </div>

          </NavLink>
            </div>
            <p>&nbsp;</p>
        </SwiperSlide>

                ))  : ''}
         </Swiper>

        {page > 0  ?

          <div className="pager mt-8 mb-4 pb-0 center columns-3 gap-1 text-center rounded-xl">
            <div className="text-center ml-14">
              <button aria-label="pagination" onClick={()=>{ loadData(`${edHost}/api/ed/story/cat/`, taxId, page, offsetItems, -1, 'dataMore');} }>
            {page > 1 ?
              <svg className="loaderMore mt-1 w-6 h-7 ml-1 inline edTextGradient rotate-180" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill="#ee2525" fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
              : ''}
              </button>
          </div>
            <div className="p-1 bg-magenta">
              <button aria-label="pagination" onClick={()=>{ loadData(`${edHost}/api/ed/story/cat/`, taxId, page, offsetItems, 1, 'dataMore');} }>
          <p className="leading-0  text-sm text-center tracking-wide font-medium pb-0"> {dataPager ? (dataPager.current_page +1)+' / '+ dataPager.total_pages : 'Load More' } </p>
          </button>
        </div>
            <div className=" text-center  mr-14 ">
              <button aria-label="pagination" onClick={()=>{ loadData(`${edHost}/api/ed/story/cat/`, taxId, page, offsetItems, 1, 'dataMore');} }>
              <svg className="mt-1 w-6 h-7 ml-1 inline edTextGradient" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill="#ee2525" fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
              </button>
            </div>
        </div>
        :''
      }
    <div className="grid grid-cols-2 gap-1">
      {dataMore ? dataMore.map((item,index) => (

        <div key={'tax_2-'+ index} style={{}} className="heightTransition rounded-xl pt-1 overflow-hidden text-center">

        <NavLink onClick={(e)=> e.currentTarget.classList.add('selected')} className="edStory glow overflow-hidden" to={item.url} state={{ from: tax }}>

        <picture style={{display:'block',overflow:'hidden'}} id={'btn-' + index} className="heightTransition">
        <ProgressiveImage
        src={edHost + item.image_thumb}
        placeholder={'/static/trans.gif'}
        modal={theme.modal}
        bg={'#333'}
        imgMargin={props.imgMargin}
        imgWidth={50}
        />
          <div className="_overlay"></div>
        </picture>

        <div className="equalHeight_2 text-left bg-magenta dark:bg-magenta">

            <p className="p-2 text-left storyTitle-sm ls-small clampStoryLinesmall pb-2">{he.decode(item.title)}</p>
        {/*   <p className="pl-2 headline-sm pb-0 ls-small">{item.date}</p>
            <p className="pl-2 edTextGradient headline-sm leading-1 ls-small pb-3">{he.decode(item.headline)}</p>
*/}

        </div>

      </NavLink>
        </div>


            ))  :

           page > 0 ?  <div className="p-24 text-center center col-span-2">
              <svg style={{margin:'0 auto'}} version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
   width="40px" height="40px" viewBox="0 0 40 40" enableBackground="new 0 0 40 40">
  <path opacity="0.2" fill="#000000" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/>
  <path fill="#fff" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
    C22.32,8.481,24.301,9.057,26.013,10.047z">
    <animateTransform attributeType="xml"
      attributeName="transform"
      type="rotate"
      from="0 20 20"
      to="360 20 20"
      dur="0.5s"
      repeatCount="indefinite"/>
    </path>
  </svg>
            </div> : ''
          }
        </div>
        {dataPager && dataPager.total_pages > 1 ?
        <div className="pager mt-8 pb-0 center columns-3 gap-1 text-center rounded-xl fadeIn delay">
          <div className="text-center ml-14">
            <button aria-label="pagination" onClick={()=>{ loadData(`${edHost}/api/ed/story/cat/`, taxId, page, offsetItems, -1, 'dataMore');} }>
          {page > 1 ?
            <svg className="mt-1 w-6 h-7 ml-1 inline edTextGradient rotate-180" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill="#ee2525" fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            : ''}
            </button>
        </div>

          <div className="p-1 bg-magenta below">
            <button aria-label="pagination" onClick={()=>{ loadData(`${edHost}/api/ed/story/cat/`, taxId, page, offsetItems, 1, 'dataMore');} }>
        <p className="leading-0  text-sm text-center tracking-wide font-medium pb-0"> {dataPager ? (dataPager.current_page +1)+' / '+ dataPager.total_pages : 'Archive' } </p>
        </button>
      </div>
          <div className=" text-center  mr-14 ">
            <button aria-label="pagination" onClick={()=>{ loadData(`${edHost}/api/ed/story/cat/`, taxId, page, offsetItems, 1, 'dataMore');} }>
            <svg className="mt-1 w-6 h-7 ml-1 inline edTextGradient" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill="#ee2525" fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </button>
          </div>
      </div>
      : ''}
   </div>
</section>

<section>

</section>




</div>
  )
});

export default TaxonomyMore
