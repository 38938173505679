import React,{useState, useEffect, useContext, memo} from 'react';
import { NavLink, Link, useLocation} from "react-router-dom";


import he from 'he';
import fetchStoryWithRetry from './functions/fetchStoryWithRetry';


import 'swiper/css';
import 'swiper/css/virtual';
import 'swiper/css/pagination';



const EdAdElite = memo( (props) => {

  const url = props.url;
  //console.log('_tax_',tax);
  const location = useLocation();
  const [dataElite,setDataElite] = useState();



  const loadDataToBlock = async () => {

    const _url = url;


    try {
      const data = await fetchStoryWithRetry(_url,props.slug);
        setDataElite(null);
        if(data){
        setDataElite(data);
        }

    } catch (error) {
      console.error('Failed to fetch the story after retries:', error);
      return null; // Handle the error appropriately
    }

  }

  useEffect(() => {
  loadDataToBlock();
    },[location]);


  return (
    <div className={props.transition}>

<section style={{overflow:'hidden'}} className={"border-b-2 border-gray-200 fadeIn w-100 pt-0 p-0 " + props.transition}>
  <p className="text-sm text-center tracking-wide pl-2 edLinkIcon p-2 pb-0">
    <strong className="linkFile text-gray-400">
    {props.name}
  </strong>
 </p>
  <div className="innerSection relative">

          { dataElite ? dataElite.map((item,index) => (

          <div key={index} className="relative block border-t-2 center text-center border-gray-200 pt-1">
          <div style={{zIndex:2, margin: '0 auto',width:'auto'}} id={'elite-' + item.id} className="bannerElite p-0 text-center" dangerouslySetInnerHTML={{__html: item.ad.replace('dev89.','') }}/>
          <a style={{zIndex:5, width:'100vw',height:'100%',position:'absolute',top:0,left:0,display:'block'}} href={item.url} target="_self" className="block">&nbsp;</a>
        </div>
          ))  : ''}

</div>
</section>
</div>
)
});

export default EdAdElite;
