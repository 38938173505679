import React,{useState, useEffect, useContext, memo} from 'react';
import { NavLink, Link, useLocation} from "react-router-dom";


import he from 'he';
import fetchStoryWithRetry from './functions/fetchStoryWithRetry';


import 'swiper/css';
import 'swiper/css/virtual';
import 'swiper/css/pagination';



const EdBlockQl = memo( (props) =>  {

  const url = props.url;
  //console.log('_tax_',tax);
  const location = useLocation();
  const [dataBlock,setDataBlock] = useState();

  var storageDate = new Date().getTime();
  var cacheExpire = 15; //expiry of cache in minutes

  const loadDataToBlock = async () => {

    const _url = url;

    const _local_data = JSON.parse(localStorage.getItem('block-Ql'));
    const _local_storageDate = JSON.parse(localStorage.getItem('d_block-Ql'));
    var _now = new Date().getTime();
    var diffInMin = (_now - _local_storageDate)/1000/60;

    if(_local_data && diffInMin < cacheExpire ){
      setDataBlock(null);
      setDataBlock(_local_data[0].body.replaceAll('https://eurodressage.com',''));
      console.log('Reading from cache for ' + 'block-Ql ' + diffInMin +' min');
      return;
    }else{
    try {
      const data = await fetchStoryWithRetry(_url,'EdBlockQl');
        setDataBlock(null);
        setDataBlock(data[0].body.replaceAll('https://eurodressage.com',''));

        window.localStorage.setItem('block-Ql',JSON.stringify(data[0].body.replaceAll('https://eurodressage.com','')));
        window.localStorage.setItem('d_block-Ql' ,storageDate);

    } catch (error) {
      console.error('Failed to fetch the story after retries:', error);
      return null; // Handle the error appropriately
    }
    }

  }

  useEffect(() => {
  loadDataToBlock();
    },[location]);


  return (
    <div className={props.transition}>

<section style={{overflow:'hidden'}} className={" fadeIn w-100 pt-0 p-2 " + props.transition}>
  <p className="text-base text-medium text-left tracking-wide pl-2 pb-2 edLinkIcon p-2">
    <strong className="linkFile"><svg className="w-4 h-4 ml-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
    {props.name}
  </strong>
 </p>
  <div className="innerSection rounded-2xl bg-featured-quickLinks">

        { dataBlock ?
          <div id="dataBlockQl" className="p-4 text-left " dangerouslySetInnerHTML={{__html: dataBlock }}/>
        : ''}

</div>
</section>
</div>
  )
});

export default EdBlockQl
