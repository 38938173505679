import React,{ useEffect, useContext, Suspense } from 'react';
import { Outlet } from "react-router-dom";

import { useMediaQuery } from 'react-responsive';
import useWindowSize from './hooks/useGatsbyWindowSize';

import AnimatedCursor from "react-animated-cursor";
import Loader from "./component/Loader";
import ScrollToTop from "./component/ScrollToTop";
import Navbar from "./component/Navbar";
import Footer from "./component/Footer";


import { ThemeContext } from './context/ThemeContext';
import './index.css';

function App() {

const {width,height} = useWindowSize();
const vertical = useMediaQuery({ query: `(orientation: portrait)` }); //detecting orientation vs max-width: 821px;

const theme = useContext(ThemeContext);

  //const [transitionStage, setTransistionStage] = useState("fadeIn");


  useEffect(()=> {
  //  theme.dispatch({submenu:theme.state.submenu, pushOut:false, width:width, height:height, vertical:vertical,type: 'viewport' });

  },[width,vertical])

  useEffect(()=> {
  var touchRegionElement = document.getElementById('app');
  var _cursor_ = touchRegionElement.getElementsByTagName('div')[0];

  function TouchMove(e) {
    _cursor_.style.opacity = 1;
    if(e.touches.length > 0){
     _cursor_.style.left = `${e.touches[0].clientX}`;
     _cursor_.style.top = `${e.touches[0].clientY}`;
     }
  }
  function TouchStart(e) {

_cursor_.style.opacity = 1;

  }
  function TouchEnd(e) {



     var t= setTimeout(function(){
       _cursor_.style.opacity = 0;
       clearTimeout(t);
     },2000);
  }

  touchRegionElement.addEventListener('touchstart', TouchStart, {passive: true});
  touchRegionElement.addEventListener('touchend', TouchEnd, {passive: true});
  touchRegionElement.addEventListener('touchmove', TouchMove, {passive: true});

  },[width])

  return (

    <div id="app" >
    <AnimatedCursor
    className="cs"
    data-cursor="1"
    showSystemCursor={true}
    trailingSpeed={12}
    innerSize={18}
      color='255, 255, 255'
      outerAlpha={0.1}
      innerScale={0.7}
      outerScale={2}
      outerSize={25}
      outerStyle={{
      boxShadow:'0 0 3px #fff, -1px 0 1px #f00, 3px 0 8px #666',opacity:0.6
  }}
  innerStyle={{
    backgroundColor: 'transparent'
  }}
      hasBlendMode={true}
      clickables={[
        'a',
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        'label[for]',
        'select',
        'textarea',
        'button',
        '.link'
      ]}
  />
   <Navbar submenu={theme.state.submenu}/>


   <div id="main" style={{width:'100%'}} className="bg-white">
     <Suspense fallback={<Loader />}>
<Outlet/>
</Suspense>
</div>



{/*
    <Routes>
      <Route path="/" element={ <Home  width={width} height={height} transition={`${'fadeIn'}`}/> } />
      <Route path="/work" element={ <Work_all  transition={`${'zoomOut'}`} /> } >
        <Route path=":id" element={<Work_view transition={`${'zoomIn'}`}/>} loader={fetchPosts} />
      </Route>
      <Route path="/blog" element={ <Blog  transition={`${transitionStage}`} /> } />
      <Route path="/team" element={ <Team transition={`${transitionStage}`} /> } />
      <Route path="/about" element={ <About transition={`${transitionStage}`}/> } />
      <Route path="/services" element={ <Services transition={`${transitionStage}`}/> } />
      <Route path="/contact" element={ <Contact transition={`${transitionStage}`}/> } />
    </Routes>
*/}
<Footer style={{position:'fixed',bottom:'0px',left:'0px'}}/>
<ScrollToTop />
</div>


  );
}

export default App;
