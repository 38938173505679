import taxIdParents from '../../data/taxIdParents'

const getKeyFromTax = function(tax,name) {

  var _key = '';
  for (var key in taxIdParents[tax]) {
          if(taxIdParents[tax][key].name === name) {
             _key = key;
          };

      }
      return _key;
}
export default getKeyFromTax;
