import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import he from 'he';
import { nanoid } from "nanoid";

const SearchForm = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [dataScore, setDataScore] = useState([]);

  const sanitizeInput = (input) => {
    // Remove any characters that are not letters, numbers, or spaces, then replace spaces with "+"
    return input.replace(/[^a-zA-Z0-9\s]/g, '').trim().replace(/\s+/g, '+');
  };

  const handleSearch = async () => {
    const sanitizedTerm = sanitizeInput(searchTerm);
    const searchUrl = `https://eurodressage.com/rest/ed/search/scores?s=${sanitizedTerm}`;

    try {
      const response = await fetch(searchUrl);
      if (response.ok) {
        const data = await response.json();
        setDataScore(data); // Store the response data in state
      } else {
        console.error('Error fetching data:', response.status);
      }
    } catch (error) {
      console.error('Network error:', error);
    }
  };

  return (
    <div className="flex flex-col items-start space-y-4 p-0">
      <div className="flex items-center space-x-2 w-full">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Enter event name, city or year "
          className="w-full px-2 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
        />
        <button
          onClick={handleSearch}
          className="px-2 py-2 text-white bg-red-600 rounded-md hover:bg-red-700 focus:outline-none"
        >
          Search
        </button>
      </div>

<div className="p-0 w-full block rounded-2xl bg-magenta mt-2 mb-4">
      <ul className="w-80 ml-4">
        {dataScore.length > 0 ? (

          dataScore.map((item, index) => (
            <React.Fragment key={nanoid()}>
            {index === 0 ? <li key="m1" className="text-sm edTextGradient pl-11 pt-4"><h3>{item.month} {item.year}</h3></li> : ''}
            {index > 0 && item.month !== dataScore[index-1].month ? <li key={'m1-'+index} className="text-sm edTextGradient pl-11 pt-4"><h3>{item.month} {item.year}</h3> </li>: ''}

            <li className="list light" key={`tax_1-${index}`}>
              <span className="nbr mr-2 text-sm">{index + 1}</span>
              <NavLink
                onClick={(e) => e.currentTarget.classList.add('selected')}
                className=" ml-4 flex"
                to={item.url}
                state={{ from: 'tax' }} // 'tax' is used here, adjust as needed
              >
                {he.decode(item.title).replace('Scores:', '')}<br/><small>{item.date}</small>
              </NavLink>
            </li>
        </React.Fragment>  ))
        ) : (
          <li className="text-gray-500"></li>
        )}

      </ul>
    </div>
    </div>
  );
};

export default SearchForm;
