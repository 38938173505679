import taxIdParents from '../../data/taxIdParents'

const getUrlFromTax = function(name) {

  var _key = '';
  for (var key in taxIdParents) {

          for (var key1 in taxIdParents[key]){
            if(taxIdParents[key][key1].name === name){
            //console.log(taxIdParents[key][key1]);
            console.log('/'+key+'/'+key1);
            if(key === key1 ){
              return '/'+key;
            }
            if(key !== key1){
              if(key == 'scores'){
                return '/'+key;
                }else{
              return '/'+key+'/'+key1;
              }
            }
            }
          }

      }
      return _key;
}
export default getUrlFromTax;
