import React from 'react'

function Legal(props) {
  return (
    <div className={"flex flex-col justify-between max-w-xl px-4 mx-auto lg:pt-16 lg:flex-row md:px-8 lg:max-w-screen-xl " + props.transition}>
ED LEGAL
</div>
  )
}

export default Legal
