const fetchStoryWithRetry = async (url, slug, retries = 3, delay = 1000) => {
  console.log(url,slug);
  for (let i = 0; i < retries; i++) {
    try {
      console.log(`%c
        ,--,
  _ ___/ /\\|
 ;( )__, )
; //   '--;
  \\     |
  ^     ^ 2024`, `font-family: monospace` );

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

       return await response.json();

    } catch (error) {
      if (i < retries - 1) {
        await new Promise((resolve) => setTimeout(resolve, delay));
      } else {
        throw error;
      }
    }
  }
};

export default fetchStoryWithRetry;
